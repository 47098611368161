import React, { useEffect } from "react";
import "./Styles/App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
import Buffet from "./Components/Buffet";
import Info from "./Components/Info";
import Menu from "./Components/Menu";

function App() {
  const closeDialog = () => {
    const dialog = document.querySelector(".dialog-update");
    dialog.close();
  };

  useEffect(() => {
    const dialog = document.querySelector(".dialog-update");
    dialog.showModal();
  }, []);
  return (
    <Router className="app-container">
      <dialog className="dialog-update">
        <h1>Important Update</h1>
        <p>
          We are currently{" "}
          <span style={{ fontWeight: "bolder", textDecoration: "underline" }}>
            closed for vacation.
          </span>{" "}
          We will be back on{" "}
          <span style={{ fontWeight: "bolder", textDecoration: "underline" }}>
            April 10th
          </span>
          . Apologies for any inconvenience. We hope to see you soon! <br />~
          Harmony Restaurant
        </p>
        <button className="dialog-close" onClick={closeDialog}>
          Close
        </button>
      </dialog>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/Buffet">
          <Buffet />
        </Route>
        <Route exact path="/Info">
          <Info />
        </Route>
        <Route exact path="/Menu">
          <Menu />
        </Route>
        {/* <Route>
          <Unknown />
        </Route> */}
      </Switch>
    </Router>
  );
}

export default App;
